
.container-box {
  box-sizing: border-box;
  font-family: PingFang SC-Medium, PingFang SC;
  color: #0f2b47;
  display: flex;
  flex-direction: column;
  .main-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .echart-top {
    display: flex;
    justify-content: space-between;
    height: 400px;
    > div:nth-child(1) {
      width: 600px;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      margin-right: 30px;
      padding: 30px;
      background: #fff;
      .flowForm {
        width: 100%;
        height: 100%;
      }
    }
    > div:nth-child(2) {
      flex: 1;
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      padding: 30px;
      background: #fff;
      .orderTrend {
        width: 100%;
        height: 100%;
      }
    }
  }
  .echart-bottom {
    height: 680px;
    box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    background: #fff;
    padding: 30px;
    .trafficTrend {
      width: 100%;
      height: 100%;
    }
  }
  .m-t-15 {
    margin-bottom: 15px;
  }
}
