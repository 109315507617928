
.data-board {
  width: 100%;
  margin-bottom: 40px;
  .data-board-title {
    font-size: 18px;
    font-weight: 500;
    color: #33475b;
    margin-bottom: 20px;
  }
  .data-board-box {
    display: flex;
    .has-icon-item {
      box-shadow: 0px 0px 32px 2px rgba(0, 0, 0, 0.1);
      border-radius: 8px 8px 8px 8px;
      margin-right: 10px;
      padding: 16px 20px;
    }
    .no-icon-item {
      padding: 16px 0 16px 55px;
    }
    .data-board-item {
      flex: 1;
      height: 134px;
      display: flex;
      justify-content: space-between;
      background: #fff;
      .item-data {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .item-title {
          font-size: 16px;
        }
        .item-num {
          font-size: 28px;
          position: relative;
          .item-plus-minus {
            position: absolute;
            top: 0;
            font-size: 13px;
            margin-left: 5px;
          }
        }
        .item-text {
          font-size: 14px;
          color: #6b747d;
          // margin-right: 6px;
        }
      }
      .item-icon {
        text-align: right;
        line-height: 102px;
      }
    }
  }
}
.red-text {
  color: #ff1743;
}
.green-text {
  color: #1ec1b0;
}
.icon-border {
  padding: 10px;
  border: 1px dashed #e5e5e9;
}
.divider {
  height: 82px;
  margin-top: 10px;
}
/deep/ .el-progress-circle {
  margin-top: 12px;
  width: 80px !important;
  height: 80px !important;
}
